import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    default: { type: Boolean, default: false },
    screens: { type: Object, default: {
      sm: false,
      md: false,
      lg: false,
      xl: false,
      '2xl': false,
    }}
  }

  connect() {
    this.dispatch('media-boolean', { detail: this.resultBoolean })
    window.addEventListener('resize', this.resize.bind(this))
  }

  disconnect() {
    window.removeEventListener('resize', this.resize.bind(this))
  }

  resize() {
    this.dispatch('media-boolean', { detail: this.resultBoolean })
  }

  get resultBoolean() {
    for(let breakpoint of this.mediaMatches.reverse()) {
      if (this.screensValue[breakpoint] !== undefined) {
        return this.screensValue[breakpoint]
      }
    }
    return this.defaultValue
  }

  get mediaMatches() {
    return Object.keys(this.breakpoints).filter((key) => {
      const breakpoint = this.breakpoints[key]
      const mediaQuery = window.matchMedia(`(min-width: ${breakpoint})`)
      return mediaQuery.matches
    })
  }

  // Should be identical with screens breakpoints in tailwind.config
  get breakpoints() {
    return {
      sm: '480px',
      md: '768px',
      lg: '1024px',
      xl: '1280px',
      '2xl': '1536px',
    }
  }
}
