import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['tab', 'jumpBtn']

  connect() {
    this.bindEvents()
  }

  get activeTabNumber() {
    return isNaN(parseInt(this.element.dataset.activeTab)) ? 1 : parseInt(this.element.dataset.activeTab)
  }

  get requiredCheckboxes() {
    return this._requiredCheckboxes =
      this._requiredCheckboxes || Array.from(this.element.querySelectorAll('input[type="checkbox"][required]'))
  }

  validate(event) {
    if (event.params.going === this.activeTabNumber) return

    const index = this.activeTabNumber - 1
    const tab = this.tabTargets[index]
    if (tab && this.reportTabValidity(tab)) {
      window.dispatchEvent(new CustomEvent('validate-tab-continue'))

      const goingTabNumber = event.params.going || (this.activeTabNumber + 1)
      this.jumpBtnTargets.filter((jumpBtn) => {
        return jumpBtn.dataset.validateTabGoingParam.toString() === goingTabNumber.toString()
      }).forEach((jumpToBtn) => {
        jumpToBtn.dispatchEvent(new CustomEvent('validate-tab-jump'))
      })
    }
  }

  addErrorClasses(input) {
    if (input.tagName === 'INPUT') {
      if (input.type === 'radio') {
        input.parentElement.parentElement.classList.add('error-radio')
      } else if (input.type === 'checkbox') {
        input.parentElement.parentElement.classList.add('error-checkbox')
      } else {
        input.parentElement.classList.add('error')
      }
    }
    if (input.tagName === 'SELECT') {
      input.parentElement.classList.add('error')
    }
    if (input.tagName === 'TEXTAREA') {
      input.parentElement.classList.add('error')
    }
  }

  removeErrorClasses(input) {
    if (input.tagName === 'INPUT') {
      if (input.type === 'radio') {
        input.parentElement.parentElement.classList.remove('error-radio')
      } else if (input.type === 'checkbox') {
        input.parentElement.parentElement.classList.remove('error-checkbox')
      } else {
        input.parentElement.classList.remove('error')
      }
    }
    if (input.tagName === 'SELECT') {
      input.parentElement.classList.remove('error')
    }
    if (input.tagName === 'TEXTAREA') {
      input.parentElement.classList.remove('error')
    }
  }

  // https://developer.mozilla.org/en-US/docs/Web/API/HTMLInputElement/invalid_event
  bindEvents() {
    const inputs = this.element.querySelectorAll('input, select, textarea')
    inputs.forEach(input => {
      input.addEventListener('invalid', (event) => {
        this.addErrorClasses(event.target)
      }, false)

      input.addEventListener('blur', (event) => {
        const input = event.target
        if (input.checkValidity()) {
          this.removeErrorClasses(input)
        } else {
          input.reportValidity()
        }
        // console.log('input.validity.valid', input.validity.valid)
      })

      input.addEventListener('click', (event) => {
        const input = event.target
        if (input.type === 'checkbox') {
          this.adjustRequiredCheckbox(input)
        }
        if (['checkbox', 'radio'].includes(input.type)) {
          input.blur()
        }
      })
    })
  }

  adjustRequiredCheckbox(input) {
    if (this.requiredCheckboxes.includes(input)) {
      this.removeErrorClasses(input)
      const groupedCheckboxes = this.requiredCheckboxes.filter((checkbox) => checkbox.name === input.name)
      if (groupedCheckboxes.some((checkbox) => checkbox.checked)) {
        groupedCheckboxes.map((checkbox) => checkbox.removeAttribute('required'))
      } else {
        groupedCheckboxes.map((checkbox) => checkbox.setAttribute('required', 'required'))
      }
    }
  }

  focusFirstErrorInput(inputs, validities) {
    const index = validities.indexOf(false)
    const input = inputs[index]
    if (input) {
      input.focus()
      input.blur()
    }
  }

  // https://developer.mozilla.org/en-US/docs/Web/HTML/Constraint_validation
  reportTabValidity(tab) {
    const inputs = tab.querySelectorAll('input, select, textarea')
    const validities = []
    Array.from(inputs).forEach(input => {
      validities.push(input.checkValidity())
    })
    this.focusFirstErrorInput(inputs, validities)
    return !validities.some((v) => !v)
  }
}
