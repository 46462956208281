import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['slide', 'pagination', 'prevBtn', 'nextBtn']
  static values = {
    options: {
      type: Object,
      default: {
        loop: true,
        direction: 'horizontal'
      }
    }
  }

  connect() {
    this.initSwiper()
  }

  get options() {
    this._options ||= {...this.optionsValue}
    if (this.hasPaginationTarget) {
      this._options.pagination = {
        ...this._options.pagination,
        el: this.paginationTarget,
      }
    }
    if (this.hasPrevBtnTarget) {
      this._options.navigation = {
        ...this._options.navigation,
        prevEl: this.prevBtnTarget,
      }
    }
    if (this.hasNextBtnTarget) {
      this._options.navigation = {
        ...this._options.navigation,
        nextEl: this.nextBtnTarget,
      }
    }
    return this._options
  }

  async initSwiper() {
    const Swiper = await this.waitSwiperReady()
    this.instance = new Swiper(this.element, this.options)
  }

  waitSwiperReady() {
    return new Promise((resolve, reject) => {
      const timtoutTimer = window.setTimeout(() => {
        reject(new Error('Swiper load fail.'))
      }, 100000)

      const intervalTimer = window.setInterval(() => {
        if (window.Swiper) {
          window.clearTimeout(timtoutTimer)
          window.clearInterval(intervalTimer)
          resolve(window.Swiper)
        }
      }, 500)
    })
  }
}
