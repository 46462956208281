import { Controller } from '@hotwired/stimulus'
import enterView from 'enter-view'
import Odometer from 'odometer'

export default class extends Controller {
  static targets = ['number']
  static values = {
    options: { type: Object, default: {} }
  }

  connect() {
    this.element.parentNode.addEventListener('animationend', this.setupEnterView.bind(this))
  }
  disconnected() {
    this.element.parentNode.removeEventListener('animationend', this.setupEnterView.bind(this))
  }
  numberTargetConnected(element) {
    this.initOdometer(element)
    this.setNumberBaseWidth(element)
  }
  get separatorWidth() {
    return '11px'
  }
  setNumberBaseWidth(element) {
    const odometerInside = element.querySelector('.odometer-inside')
    if (odometerInside) {
      const numberLength = element.dataset.value.length
      const separatorLength = Math.floor(element.dataset.value.length / 3)
      odometerInside.style.minWidth = `calc(${numberLength}ch + (${separatorLength} * ${this.separatorWidth}))`
    }
  }
  initOdometer(element) {
    return new Odometer({
      auto: true,
      el: element,
      value: 0,
      format: '(,ddd)',
      theme: 'bznk',
      animation: 'slide',
      duration: 4000,
      ...this.optionsValue
    })
  }
  setupEnterView() {
    enterView({
      selector: this.numberTargets,
      once: false,
      offset: 0.1,
      enter: this.startNumberCounting.bind(this)
    })
  }
  startNumberCounting(element) {
    element.innerText = element.dataset.value
  }
}
