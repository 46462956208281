import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['cube', 'intersectionRoot']

  get intersectionObserver() {
    // "null" means Viewport of document
    return this._intersectionObserver ||= new IntersectionObserver(this.observeCallback.bind(this), {
      root: this.hasIntersectionRootTarget ? this.intersectionRootTarget : null
    })
  }

  // https://developer.mozilla.org/en-US/docs/Web/CSS/@media/hover
  get isHoverable() {
    return window.matchMedia('(hover:hover)').matches
  }

  randomSeconds(min, max) {
    return Math.floor((min + Math.random() * (max - min)) * 1000)
  }

  observeCallback(entries) {
    this.isElementInViewport = entries.every((entry) => entry.isIntersecting)

    entries.forEach((entry) => {
      if (entry.isIntersecting && !this.isHoverable) {
        entry.target.dispatchEvent(new CustomEvent('auto-flip-start'))
      } else {
        entry.target.dispatchEvent(new CustomEvent('auto-flip-end'))
      }
    })
  }

  connect() {
    document.addEventListener('readystatechange', this.readyStateChange.bind(this))
    this.flipped = false
    this.element.addEventListener('auto-flip-start', this.moniterHandler.bind(this))
    this.element.addEventListener('auto-flip-end', this.clearMoniterHandler.bind(this))
  }

  disconnect() {
    this.intersectionObserver.unobserve(this.element)
    this.element.removeEventListener('auto-flip-start', this.moniterHandler.bind(this))
    this.element.addEventListener('auto-flip-end', this.clearMoniterHandler.bind(this))
    this._intersectionObserver = null

    if (this._monitorInterval) {
      window.clearInterval(this._monitorInterval)
    }
  }

  readyStateChange(e) {
    if (e.target.readyState ==='complete') {
      this.intersectionObserver.observe(this.element)
    }
  }

  moniterHandler(e) {
    if (this.element === e.target) {
      this.cubeTarget.classList.add('transition-all', 'duration-500')
      this._monitorInterval = window.setInterval(this.intervalHandler.bind(this), this.randomSeconds(3, 5))
    }
  }

  clearMoniterHandler(e) {
    if (this.element === e.target && this._monitorInterval) {
      window.clearInterval(this._monitorInterval)
    }
  }

  intervalHandler() {
    this.flipped = !this.flipped
    if (this.flipped) {
      this.flipToFrontSide()
    } else {
      this.flipToLeftSide()
    }
  }

  flipToFrontSide() {
    this.cubeTarget.classList.remove('rotate-y-180')
  }

  flipToLeftSide() {
    this.cubeTarget.classList.add('rotate-y-180')
  }
}
