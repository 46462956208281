import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['content', 'form']
  static values = {
    visible: {
      type: Boolean,
      default: false,
    },
  }

  initialize() {
    this._visible = this.visibleValue
    this.refresh()
  }

  open() {
    this._visible = true
    this.refresh()
  }

  close() {
    this._visible = false
    this.refresh()
  }

  refresh() {
    if (this._visible) {
      this.contentTarget.classList.replace('scale-0', 'scale-100')
      this.focus()
    } else {
      this.contentTarget.classList.replace('scale-100', 'scale-0')
    }
  }

  focus() {
    if (this.hasFormTarget) {
      this.searchInput.focus()
    }
  }

  closeIfBlank() {
    if (this.searchInput && this.searchInput.value.length === 0) {
      this.close()
    }
  }

  get searchInput() {
    if (this.hasFormTarget) {
      return this.formTarget.querySelector('[type="search"]')
    }
    return null
  }
}
