import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = []
  static values = {
    wrapClass: {
      type: Object,
      default: {
        landscape: 'flex-row',
        portrait: 'flex-col'
      }
    },
    gapWidths: {
      type: Object,
      default: {
        default: 5,
        sm: 10,
        md: 20,
        lg: 20,
        xl: 20,
        '2xl': 20,
      }
    }
  }

  connect() {
    window.addEventListener('resize', this.resize.bind(this))
    this.resize()
  }

  resize() {
    if (this.getWidth(this.element) <= this.totalWidthOfChildNodes) {
      this.landscapeToPortrait()
    } else {
      this.portraitToLandscape()
    }
  }

  landscapeToPortrait() {
    this.element.classList.remove(...this.landscapeClasses)
    this.element.classList.add(...this.portraitClasses)
  }

  portraitToLandscape() {
    this.element.classList.remove(...this.portraitClasses)
    this.element.classList.add(...this.landscapeClasses)
  }

  get widthOfNodes() {
    return Array.from(this.element.childNodes).map((node) => this.getWidth(node))
  }

  get gapWidth() {
    for(let breakpoint of this.mediaMatches.reverse()) {
      if (this.gapWidthsValue[breakpoint] !== undefined) {
        return this.gapWidthsValue[breakpoint]
      }
    }
    return this.gapWidthsValue.default
  }

  get totalWidthOfChildNodes() {
    return this.arrayJoin(this.widthOfNodes, this.gapWidth).reduce((acc, width) => acc + width)
  }

  get landscapeClasses() {
    return this.wrapClassValue.landscape.split(' ')
  }

  get portraitClasses() {
    return this.wrapClassValue.portrait.split(' ')
  }

  getWidth(node) {
    return node.getBoundingClientRect()?.width
  }

  arrayJoin(array, element) {
    return array.flatMap((item) => {
      return [element, item]
    }).slice(1)
  }

  get mediaMatches() {
    return Object.keys(this.breakpoints).filter((key) => {
      const breakpoint = this.breakpoints[key]
      const mediaQuery = window.matchMedia(`(min-width: ${breakpoint})`)
      return mediaQuery.matches
    })
  }

  // Should be identical with screens breakpoints in tailwind.config
  get breakpoints() {
    return {
      sm: '480px',
      md: '768px',
      lg: '1024px',
      xl: '1280px',
      '2xl': '1536px',
    }
  }
}