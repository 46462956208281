import { Controller } from '@hotwired/stimulus'

const ONCE_DONE = 'ONCE_DONE!!!'
export default class extends Controller {
  static targets = ['intersectionRoot', 'queueItem']
  static values = {
    timing: { type: String, default: 'when-visible' },
    effect: { type: String, default: 'fade-in' },
    once: { type: Boolean, default: false },
    offset: { type: Number, default: 0.1 },
    delay: { type: Number, default: 0 } // second
  }

  get intersectionObserver() {
    // "null" means Viewport of document
    return this._intersectionObserver ||= new IntersectionObserver(this.observeCallback.bind(this), {
      root: this.hasIntersectionRootTarget ? this.intersectionRootTarget : null
    })
  }

  get isOnceDone() {
    if (this.isQueueMode) {
      return this.queueItems.every((item) => item.dataset.status === ONCE_DONE )
    } else {
      return this.element.dataset.status === ONCE_DONE
    }
  }

  get isQueueMode() {
    return this.hasQueueItemTarget
  }

  get queueItems() {
    return this.isQueueMode ? this.queueItemTargets : [this.element]
  }

  get animateClass() {
    switch(this.effectValue) {
    case 'fade-in':
      return 'animate__fadeIn'
    case 'fade-in-down':
      return 'animate__fadeInDown'
    case 'fade-in-up':
      return 'animate__fadeInUp'
    case 'fade-in-right':
      return 'animate__fadeInRight'
    default:
      return 'animate__fadeIn'
    }
  }

  observeCallback(entries) {
    this.isElementInViewport = entries.every((entry) => entry.isIntersecting)

    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        if (!this.isOnceDone) {
          this.dispatch('entry-intersecting', { detail: { item: entry.target }})
        }

        if (this.onceValue) {
          this.markDone(entry.target)
        }
      } else {
        if (!this.isOnceDone) {
          this.dispatch('entry-leaving', { detail: { item: entry.target }})
        }
      }
      this.dispatch('item-reset', { detail: { item: entry.target, isIntersecting: entry.isIntersecting }})
    })
  }

  connect() {
    document.addEventListener('readystatechange', this.readyStateChange.bind(this))
    this.element.addEventListener('animation:entry-intersecting', this.entryIntersecting.bind(this))
    this.element.addEventListener('animation:entry-leaving', this.entryLeaving.bind(this))
    this.element.addEventListener('animation:item-reset', this.itemReset.bind(this))
  }

  disconnect() {
    this.queueItems.forEach((item) => {
      this.intersectionObserver.unobserve(item)
    })
    this.element.removeEventListener('animation:entry-intersecting', this.entryIntersecting.bind(this))
    this.element.removeEventListener('animation:entry-leaving', this.entryLeaving.bind(this))
    this.element.removeEventListener('animation:item-reset', this.itemReset.bind(this))
    this._intersectionObserver = null
  }

  readyStateChange(e) {
    if (e.target.readyState ==='complete') {
      this.queueItems.forEach((item) => {
        this.intersectionObserver.observe(item)
      })
    }
  }

  entryIntersecting(e) {
    const item = e.detail.item
    this.setupDelay(item)
    item.classList.add(this.animateClass, 'animate__animated')
  }

  entryLeaving(e) {
    const item = e.detail.item
    this.discardDelay(item)
    item.classList.remove('animate__animated')
  }

  itemReset(e) {
    const { item, isIntersecting } = e.detail

    if (this.effectValue === 'fade-in') {
      if (isIntersecting) {
        if (item.classList.contains('animate__animated')) {
          item.classList.remove('duration-0', 'opacity-0')
        }
      } else {
        item.classList.add('duration-0', 'opacity-0')
      }
    }
  }

  setupDelay(item) {
    const seconds = this.isQueueMode ? (item.dataset.delay || 0) : (this.delayValue || 0)
    item.style.setProperty('animation-delay', `${seconds}s`)
  }

  discardDelay(item) {
    item.style.removeProperty('animation-delay')
  }

  markDone(item) {
    item.dataset.status = ONCE_DONE
  }
}
